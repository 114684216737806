import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ResourcesPage = () => (
  <Layout>
    <SEO title="Resources" />
    <section className="intro">
      <div className="intro-container">
        <h2>Resources</h2>
        <p>Learn more about how you can help support and ally with Asians and other people of color by combating anti-Blackness, misogyny, transphobia, ableism, and anti-Asian racism in solidarity with Asian kinksters and fetishists.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Check back soon for more resources.</p>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default ResourcesPage
